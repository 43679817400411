<template>
  <div class="card cert-card d-flex justify-content-center">
    <div class="card-body">
      <p>{{ message }}</p>
    </div>
  </div>
</template>

<script>
import * as Api from '../api'

export default {
  name: 'kakao',
  components: {},
  data() {
    return {
      query: null,
      access_token: null,
      message: '카카오에서 인증코드를 받았습니다.'
    }
  },
  created() {
    this.query = this.$route.query
  },
  mounted() {
    this.getToken()
  },
  methods: {
    getToken() {
      if (this.query.code) {
        const params = new URLSearchParams()
        params.append('grant_type', 'authorization_code')
        params.append('client_id', this.$PUB.kakao.clientId)
        params.append('redirect_uri', this.$PUB.url + this.$PUB.kakao.redirect_uri)
        params.append('code', this.query.code)
        // const config = {
        //   headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        // }
        console.log(params)
        this.$axios
          .post('https://kauth.kakao.com/oauth/token', params)
          .then(res => {
            this.message = '카카오에서 사용자 토큰을 받았습니다.'
            this.access_token = res.data.access_token
            console.log('get!!', res.data)
            this.klogin()
          })
          .catch(err => {
            this.$notify({ text: err.message, type: 'error', group: 'error' })
            console.log(err.message)
          })
      }
    },

    klogin() {
      this.message = '로그인중입니다...'
      if (this.access_token) {
        Api.auth
          .klogin(this.access_token)
          .then(data => {
            if (data.customToken) {
              this.signInWithCustomToken(data.customToken)
            }
            console.log(data)
          })
          .catch(err => {
            this.$notify({ text: err.error, type: 'error', group: 'error' })
            console.log(err)
            this.message = '이미 등록중인 이메일입니다. 등록된 이메일로 로그인하세요.'
          })
      }
    },

    signInWithCustomToken(customToken) {
      this.message = '파이어베이스 로그인중입니다...'
      this.$firebase
        .auth()
        .signInWithCustomToken(customToken)
        .then(() => {
          self.close()
        })
        .catch(err => {
          this.$notify({ text: err.message, type: 'error', group: 'error' })
          console.log(err.message)
        })
    }
  }
}
</script>
<style></style>
